import { FC } from "react";
import styled from "styled-components";

const Page:FC = () => {

	return (
		<Root>
			not implemented
		</Root>
	);
};


const Root = styled.div`
	
`;

export default Page;